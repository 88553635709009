@import '../../../../../assets/_var.scss';

.collectibles_page {
    .contact_ques {
        white-space: normal;
        min-width: 40rem;
    }
    table {
        thead {
            tr {
                th {
                    &:first-child {
                        width: 20rem !important;
                    }
                }
            }
        }
    }
}

