@import "../../../../assets/_var.scss";

.products_page {
  .filter_form {
    display: flex;
    margin-bottom: 2rem;

    .common_btn {
      margin-left: auto;
    }
  }

  table {
    tbody {
      tr {
        td {
          .update_btn {
            background-color: $basecolor2;
            padding: 0.8rem 1.6rem;
            border-radius: 0.8rem;
            color: $black;
            border: none;
            outline: none;
            transition: 200ms ease-in-out;

            &:hover {
              background-color: $baseColor;
            }
          }
        }
      }
    }
  }

  select {
    cursor: pointer;
    outline: none;
    background-color: $black;
    color: $white;
  }

}