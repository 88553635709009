@import "../../../../../assets/_var.scss";

.product_modal {
  .modal-dialog {
    max-width: 80rem;
  }

  &_content {
    &_item {
      position: relative;
      display: flex;
      height: 17.2rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $baseColor;
      border-radius: 1.8rem;
      background-color: $black;
      margin-bottom: 2.4rem;

      h3 {
        font-size: 2rem;
        color: $darkorange;
        font-weight: 500;
        margin-top: 2rem;

        @media (max-width: 1439px) {
          font-size: 1.8rem;
        }

        @media (max-width: 991px) {
          font-size: 1.6rem;
        }

        @media (max-width: 767px) {
          font-size: 1.4rem;
          text-align: center;
        }
      }

      .common_input {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;

        &_inner {
          height: inherit;

          .upload_sec {
            cursor: pointer;
            height: inherit;
          }
        }
      }
    }
  }

  .updateBlogImage {
    align-items: center;
    width: 100%;
    height: 172px;
    position: relative;
    background: #f6f6f6;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}