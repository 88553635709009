@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

$baseFont: "Poppins", sans-serif;

// colors
$gray: #4f4f4f;
$lightGray: #6e6e73;
$lightGray2: #bbbbbb;
$blueGray: #f8fafc;
$extraLight: #d0d5dd;
$redColor: #f0263c;
$redColor2: #eb5757;
$inputborder: #D9D9D9;
$inputbg : #F0F0F0;
$gray2: #7D7D7D;
$gray3: #F6F6F6;
$yellow: #FFD08B;
$gray4: #BDBDBD;
$graylight: #ECECEC;
$borderclr: #EDEDED;
$graybg: #F5F5F5;
$graybg2: #E8E8E8;
$gray5: #828282;
$F2F2F2: #f2f2f2;
// border-color
$border-color: #e0e0e0;
$border-color2: #e1e1df;
// $darkorange: #e48e10;
$orange: #FF8A00;
$darkorange: #e48e10;
$redcolor3: #EF2323;
$opacitygreen: rgba(0, 166, 166, 0.07);
$lightsky: #EDF9F9;
$grey4: #333333;

// gradient
$baseGradient: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
$gradient: linear-gradient(179.94deg, rgba(0, 166, 166, 0.1) 0.05%, rgba(0, 166, 166, 0.09) 50%, rgba(0, 166, 166, 0.1) 99.95%);

//box shaddow
$boxShadow2: 0px 6px 16px rgba(0, 0, 0, 0.12);



// *** new colors
$basecolor2: #FEC045;
$basecolor3: #FFBA55;
$black: #000;
$white: #ffffff;
$baseColor: #FFD08B;
$secondaryColor: #e48e10;
$black23: #232323;
$yellowclr: #ECC44B;
$textclr: #C0C0C0;
$textclr2: #B2B2B2;