@import "../../../../assets/_var.scss";

.collectible_type_page {
    .sub_category_btn {
        color: $basecolor2;
        background-color: rgba($baseColor, 0.2);
        border-radius: 0.8rem;
        border: none;
        outline: none;
        padding: 0.8rem 1.6rem;
        transition: 200ms ease-in-out;
        margin-right: 0.8rem;

        &:hover {
            background-color: rgba($baseColor, 0.4);
        }
    }
}