@import "../../../../assets/_var.scss";

.top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 5rem;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.06);
  transition: 200ms ease-in-out;
  background-color: rgba($black, 0.1);
  position: sticky;
  top: 0;
  z-index: 999;

  @media (max-width:767px) {
    padding: 1rem 2rem;
  }

  .heading {
    font-weight: 600;
    font-size: 2.4rem;
    color: $darkorange;
  }

  .header_right_links {
    display: flex;
    align-items: center;

    @media (max-width:767px) {
      justify-content: space-between;
    }

    .wallet_address {
      display: flex;
      align-items: center;
      margin-right: 3.4rem;


      p {
        font-weight: 500;
        color: $gray2;

        @media (max-width:767px) {
          font-size: 1.4rem;
        }

        span {
          margin-right: 0.6rem;
        }
      }

      .balance_value {
        color: $secondaryColor;
        margin-left: 0.6rem;
      }
    }
  }

  .header_toggle {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    display: none;

    @media (max-width: 767px) {
      margin-left: 01rem;
      display: inline-block;
    }
  }

  // .toggleBtn {}
  &.scrolled {
    background-color: rgba($black , 4);
  }

}