@import "../../../../assets/_var.scss";

.login-page {
  background-image: url(../../../../assets/images/img/login-bg.png);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;


  &::before {
    content: "";
    position: absolute;
    background-image: url(../../../../assets/images/img/login-overlay.svg);
    opacity: 1;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

  }

  .auth-card {
    padding: 4.6rem 5rem;

    @media (max-width: 1679px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    @media (max-width: 991px) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }

    .common-heading {
      margin-bottom: 4.3rem;

      @media (max-width: 1679px) {
        margin-bottom: 2rem;
      }

      // @media (max-width: 991px) {
      //   margin-bottom: 2rem;
      // }
    }
  }

  .link_text {
    width: fit-content;
    display: block;
    margin: 0 0 2rem auto;
    color: $baseColor;

    &:hover {
      color: $basecolor2;
    }
  }

  &__frgtPswd {
    margin-top: -2.5rem;
    margin-bottom: 3.6rem;

    @media (max-width: 1679px) {
      margin-top: -1.5rem;
      margin-bottom: 1rem;
    }

    @media (max-width: 575px) {
      margin-top: -0.5rem;
      margin-bottom: 1rem;
    }
  }
}

.sign-up {
  h5 {
    margin-bottom: 2.5rem;
    color: $secondaryColor;

    @media (max-width: 1679px) {
      margin-bottom: 1rem;
    }
  }
}


.change_pwd {

  .otp_input {
    label {
      color: $basecolor2;
    }
  }

  .common-heading {
    margin-bottom: 3.6rem !important;

    // p {
    //   display: none;
    // }
  }

  .group_form {
    position: relative;

    .eyeIcon {
      position: absolute;
      right: 1rem;
      top: 5.5rem;

      @media (max-width: 1679px) {
        top: 4rem;
      }

      @media (max-width: 575px) {
        top: 3.4rem;
      }
    }
  }
}