@import "../../../../../assets/_var.scss";

.select {
  margin-bottom: 3.4rem;

  @media (max-width: 1679px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }

  :global {
    .form-label {
      font-weight: 400;
      font-size: 1.8rem;
      color: $baseColor;
      margin-bottom: 0.8rem;
    }
  }

  :global {
    .react-select__control {
      height: 6.2rem;
      border: 0.1rem solid $black;
      color: $white;
      background-color: $black;
      box-shadow: none;

      @media (max-width: 1679px) {
        height: 5rem;
      }

      @media (max-width: 575px) {
        height: 4rem;
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
      }

      .react-select__value-container {
        padding-left: 3rem;
        color: $white;

        .react-select__input-container,
        .react-select__single-value {
          color: $white;
        }
      }

      &.react-select__control--is-focused {
        border-color: $basecolor2;
      }
    }

    .react-select__menu {
      background-color: $black;

      &-list {
        .react-select__option {
          &.react-select__option--is-focused {
            background-color: transparent;
          }

          &.react-select__option--is-selected,
          &:hover {
            background-color: $basecolor2;
            color: $black
          }
        }
      }
    }
  }
}