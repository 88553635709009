@import '../../../../assets/_var.scss';


.dropdown {
    .dropdown-toggle {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8rem;
        letter-spacing: 0.01em;

        &:focus,
        &:hover,
        &:active,
        &.show {
            border: none;
            background-color: transparent !important;
        }
    }

    .dropdown-menu {
        background-color: rgba($black23, 1);
        padding: 0;
        border: 1px solid rgba($white, 0.2);
        left: unset !important;
        right: 0 !important;
        margin-top: 1rem;

        .dropdown-item {
            padding: 0.8rem 1rem;
            font-size: 1.6rem;
            width: 20rem;
            color: $white;
            padding: 0.8rem 2rem;
            background-color: transparent;
            display: block;
            font-size: 1.6rem;

            @media (max-width: 1439px) and (max-height: 767px) {
                font-size: 1.4rem;
            }

            @media (max-width: 1439px) and (max-height: 619px) {
                font-size: 1.2rem;
            }

            @media (max-width: 1439px) and (max-height: 621px) {
                font-size: 1.2rem;
            }

            &:hover {
                background-color: rgba($white, 0.08);
            }

            >span {
                margin-right: 0.6rem;
            }
        }
    }
}