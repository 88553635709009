@import "~bootstrap/scss/bootstrap";
@import "./assets/_var.scss";

html {
  font-size: 62.5%;

  body {
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    font-family: $baseFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $baseGradient;
    color: $baseColor;
  }
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// reset css

.row {
  margin: 0 -15px;

  >div {
    padding: 0 15px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  caret-color: $black;
  -webkit-text-fill-color: $black;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  caret-color: $black;
  -moz-text-fill-color: $black;
}


input[type='date'] {
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

a,
span {
  text-decoration: none;
  display: inline-block;
}

img,
svg,
video,
canvas {
  max-width: 100%;
}

ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

ul,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.table {
  --bs-table-bg: transparent;
}


.c-pointer {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $black inset !important;
    caret-color: $white;
    border-color: $basecolor2;
  }

input:-webkit-autofill{
  -webkit-text-fill-color: $white !important;
  caret-color: $white;
  border-color: $basecolor2;
}