@import "../../../../../assets/_var.scss";

.delete_modal {

    .modal-body {
        text-align: center;
        padding: 4rem 2rem;
    }

    &_content {

        svg {
            margin-bottom: 3.8rem;
        }

        p {
            font-size: 1.8rem;
            font-weight: 400;
            margin: 0 0 2rem;
        }

        .btn_sec {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            button {
                margin-top: 1rem;

                &:last-child {
                    margin-top: 0;
                }
            }
        }
    }
}