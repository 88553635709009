@import "../../../../assets/_var.scss";

.page_nation {
  display: flex;
  margin-top: 3rem;
  justify-content: center;

  .pagination {
    .page-item {
      .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $secondaryColor;
        border: none;
        background-color: $graylight;
        margin-right: 0.6rem;
        font-weight: 600;
        font-size: 1.6rem;
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 0.4rem;

        &:focus {
          box-shadow: unset;
        }
      }

      &.disabled {
        .page-link {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      // &:first-child{
      //     display: none;
      // }
      // &:nth-child(5){
      //     .page-link{
      //         background:none;
      //     }
      // }
      &.active {
        .page-link {
          background-color: $secondaryColor;
          color: $white;
          cursor: pointer;
        }
      }

      // &:first-child{
      //     display: none;
      // }
      &:first-child {
        .page-link {
          background-color: transparent;
          background-image: url(../../../../assets/images/icons/prev-arrow.svg);
          background-repeat: no-repeat;
          background-size: 9px;
          background-position: center;
          font-size: 0;
        }
      }

      &:last-child {
        .page-link {
          background-color: transparent;
          background-image: url(../../../../assets/images/icons/next-arrow.svg);
          background-repeat: no-repeat;
          background-size: 9px;
          background-position: center;
          font-size: 0;
        }
      }
    }
  }
}