@import "../../../../../assets/_var.scss";

.common_modal {
    .modal-dialog {
        padding: 1.2rem;

        .modal-content {
            background-color: $black23;
            border: 2px solid $basecolor3;
            box-shadow: 0px 0px 40px 0px #FFBA554D;
            border-radius: 0;

            .modal-header {
                border-bottom: 0;
                display: flex;
                padding: 2rem 3rem;
                align-items: center;

                @media (max-width: 991px) {
                    padding: 1rem 2rem;
                }

                .close_btn {
                    background-color: transparent;
                    border: none;
                    outline: none;
                }
            }

            .modal-body {
                padding: 2rem 3rem;

                @media (max-width: 991px) {
                    padding: 1rem 2rem;
                }
            }
        }
    }
}