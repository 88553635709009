@import "../../../../../assets/_var.scss";

.common_date {
  .react-datepicker-wrapper {
    height: 100%;

    .react-datepicker__input-container {
      height: inherit;
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        background-image: url(../../../../../assets/images/icons/calendar.svg);
        background-size: 100%;
        width: 2.4rem;
        height: 2.4rem;
        background-repeat: no-repeat;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 3rem;

        @media (max-width: 1679px) {
          right: 1.2rem;
          width: 2rem;
          height: 2rem;
        }
      }
      input {
        background-color: transparent;
        position: relative;
        z-index: 1;
      }
    }
  }

  .react-datepicker {
    font-size: 1.4rem;

    &__day-name,
    &__day,
    &__time-name {
      margin: 0.7rem;
    }

    &__current-month {
      font-size: 1.2rem;
    }
  }
}
