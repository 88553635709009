@import "../../../../assets/_var.scss";

.common_table {
  .table-responsive {
    &::-webkit-scrollbar {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 4rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 1rem;
      background-color: $basecolor2;
    }
  }

  &.serialised {

    thead,
    tbody {
      tr {

        th,
        td {
          &:first-child {
            padding: 0 0.8rem 0 0.8rem;
            width: 3rem;
            text-align: center;
          }
        }
      }
    }
  }

  table {
    border-spacing: 0 0.4rem;
    border-collapse: separate;

    thead {
      tr {
        th {
          background-color: rgba($yellowclr, 0.1);
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.8rem;
          letter-spacing: 0.01em;
          color: $textclr;
          padding: 1.4rem 2rem;
          vertical-align: middle;
          white-space: nowrap;
          border: none;

          &:first-child {
            border-radius: 0.4rem 0 0 0.4rem;
            padding-left: 4rem;
          }

          &:last-child {
            border-radius: 0.4rem 0.4rem 0 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border: none;
          background-color: rgba($white, 0.1);
          color: rgba($white, 0.6);
          font-size: 1.6rem;
          font-weight: 400;
          padding: 0.9rem 2rem;
          vertical-align: middle;
          white-space: nowrap;
          line-height: 2.2rem;
          text-align: left;

          &:first-child {
            border-radius: 0.4rem 0 0 0.4rem;
            padding-left: 4rem;
          }

          &:last-child {
            border-radius: 0.4rem 0.4rem 0 0;
          }

          .asset_img {
            width: 4.2rem;
          }

          @media (max-width: 479px) {
            font-size: 1.4rem;

            &:first-child {
              border-radius: 0.4rem 0.4rem 0 0;
              padding: 1.4rem 2rem;
            }

            &:last-child {
              border-radius: 0 0 0.4rem 0.4rem;
            }
          }

          &.no_data_found {
            padding: 2rem 1rem !important;
          }

          .action_box {
            display: flex;
            align-items: center;

            .action_btn {
              width: 4rem;
              background-color: rgba($white, 0.1);
              border: none;
              height: 4rem;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;

              &:not(:last-child) {
                margin-right: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}