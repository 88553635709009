@import '../../../../assets/_var.scss';

.primary_layout {
    min-height: 100vh;
    background-image: url(../../../../assets/images/auth-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    padding-left: 26rem;

    .primary_layout__right__pages {
        padding: 4rem 2rem 0;
    }

    @media (max-width: 767px) {
        padding-left: 0;
    }
}