@import "../../../../../assets/_var.scss";

.common_input {
  position: relative;
  margin-bottom: 3.4rem;

  @media (max-width: 1679px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 400;
    font-size: 1.8rem;
    color: $baseColor;
    margin-bottom: 0.8rem;

    @media (max-width: 1679px) {
      font-size: 1.4rem;
      margin-bottom: 0.4rem;
    }

    @media (max-width: 575px) {
      font-size: 1.2rem;
    }
  }

  &_inner {
    position: relative;

    .leftIcon,
    .rightIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .leftIcon {
      position: absolute;
      left: 2rem;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }
    }

    .rightIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2.6rem;
      background-color: transparent;

      &.btn {
        cursor: pointer;
      }

      @media (max-width: 1679px) {
        right: 1.2rem;

        svg {
          max-width: 2rem;
        }
      }

      @media (max-width: 1679px) {
        svg {
          max-width: 1.5rem;
        }
      }
    }

    input,
    textarea,
    .select {
      height: 6.2rem;
      width: 100%;
      border: 0.1rem solid $black;
      border-radius: 0.8rem;
      padding: 1.25rem 3.4rem;
      outline: none;
      font-size: 1.6rem;
      font-weight: 400;
      color: $white;
      background-color: $black;

      @media (max-width: 1679px) {
        height: 5rem;
        font-size: 1.3rem;
        padding: 1rem 2.5rem;
      }

      @media (max-width: 575px) {
        height: 4rem;
        font-size: 1.1rem;
        padding: 1rem 1.5rem;
      }

      &:focus,
      &:hover {
        background-color: $black;
        border-color: $basecolor2;
        color: $white;
        box-shadow: none;
      }

      &::placeholder {
        color: $lightGray2;
      }

      &::-webkit-input-placeholder {
        color: $lightGray2;
      }

      &:-moz-placeholder {
        color: $lightGray2;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $white inset;
        border: 1px solid $lightGray2;
        color: $lightGray2;
      }
    }

    &.rightIconInput {
      input {
        padding-right: 7.5rem;

        @media (max-width: 1679px) {
          padding-right: 5rem;
        }

        @media (max-width: 575px) {
          padding-right: 3rem;
        }
      }
    }

    &.leftIconInput {
      input {
        padding-left: 4.75rem;
      }
    }
  }
}