@import "../../../../assets/_var.scss";

.commonBtn {
  // margin: 2rem;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  border-radius: 1rem;
  border: none;
  width: 25.1rem;
  height: 6.4rem;
  font-weight: 600;
  background-image: url(../../../../assets/images/img/button.png);
  background-size: cover;
  transition: 0.3s all ease-in-out;
  color: $white;
  text-align: center;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  &:disabled,
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media (max-width: 1679px) {
    font-size: 1.6rem;
    padding: 0 1rem;
  }

  @media (max-width: 991px) {
    font-size: 1.4rem;
    width: 16rem;
    height: 4rem;
  }

  @media (max-width: 575px) {
    font-size: 1.2rem;
    padding: 0 1rem;
  }
}