@import '../../../../assets/_var.scss';

.NFT_Management {
    .nft_heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nft_mange_tab {
            width: 100%;
        }

        .common_input {
            .form-label {
                display: none;
            }
        }
    }
}

/*nft_tab*/

.nft_tab {
    .nft_tab_links {
        border-radius: 1.4rem;
        // margin-top: 4rem;
        width: 100%;

        @media (max-width:1679px) {
            margin-top: 2rem;
        }

        .tab_links {
            display: flex;
            @media (max-width:575px) {
                width: 100%;
            }
            .nav-item {
                @media (max-width:575px) {
                    width: 100%;
                }
                &:last-child {
                    .nav-link {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: 0.8rem;
                        border-bottom-right-radius: 0.8rem;
                    }
                }

                .nav-link {
                    // max-width: 210px;
                    // width: 100%;
                    height: 6rem;
                    border-radius: 0.8rem;
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: $gray2;
                    background: $graybg;
                    padding: 1.8rem 3.5rem;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    @media (max-width:1679px) {
                        height: 5.7rem;
                        font-size: 1.4rem;
                    }

                    @media (max-width:575px) {
                        font-size: 1.2rem;
                        padding: 2rem;
                        text-align: center;
                    }
                    @media (max-width:379px) {
                        font-size: 1.1rem;
                    }

                    &:hover {
                        border-color: transparent;
                    }

                    &.active {
                        background-color: $secondaryColor;
                        color: $white;
                        font-weight: 600;
                    }
                }
                .wallet_link{
                    padding: 1.8rem 6.5rem;
                    @media (max-width:479px) {
                        padding: 1.8rem 4.5rem;
                    }
                }
            }
        }

        .filter_form {
            margin-left: auto;

            @media (max-width:1679px) {
                margin-left: 0;
                margin-top: 1.5rem;
            }
        }
    }
}

/*NFT wallet css*/
.tab_wallet{
    align-items: center;
}
.wallet_balance {
    margin-left: auto;
    display: flex;
    @media (max-width:575px) {
        margin-left: initial;
        margin-top: 1.5rem;
    }
    p {
        font-weight: 500;
        color: $gray2;

        @media (max-width:767px) {
            font-size: 1.4rem;
        }
    }

    .balance_value {
        color: $secondaryColor;
        margin-left: 0.6rem;
    }
}