@import "../../../../assets/_var.scss";

.auth-layout {
  background-color: $white;
  position: relative;
  padding: 5.4rem 0;
  min-height: 100vh;
  display: flex;
  align-items: center;

  @media (max-width: 1679px) {
    padding: 3.4rem 0;
  }

  .container {
    max-width: 1470px;
    padding-inline: 1.5rem;

    @media (max-width: 1679px) {
      max-width: 1280px;
    }
  }

  &__logo {
    margin: 6rem auto;
    max-width: 12rem;

    @media (max-width: 1679px) {
      margin: 4rem auto;
    }

    @media (max-width: 767px) {
      max-width: 12rem;
      margin: 0 auto 2.8rem;
    }
  }

  &__left &__right {
    min-height: 100%;

    // min-height: calc(100vh - 10.8rem);

    // @media (max-width: 1679px) {
    //   min-height: 100%;
    // }

    // @media (max-width: 767px) {
    //   min-height: fit-content;
    // }
  }

  &__left {
    &__img {
      @media (max-width: 1679px) {
        margin: 0 auto;
        max-width: 48rem;
      }
    }
  }

  &__closeBtn {
    padding: 0 !important;
    position: absolute;
    top: 3.5rem;
    right: 3rem;
    opacity: 50;
    transition: all linear 0.2s;

    @media (max-width: 1679px) {
      top: 0.5rem;
      right: 1rem;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__btmText {
    margin-top: 3.5rem;

    @media (max-width: 1679px) {
      margin-top: 2rem;
    }

    @media (max-width: 575px) {
      margin-top: 1rem;
    }
  }

  .auth-card {
    width: 100%;
    // background: $white;
    // background: rgba(35, 35, 35, 0.80);
    // border: 0.1rem solid $border-color;
    border-radius: 0.8rem;
    padding: 5rem;

    @media (max-width: 1679px) {
      padding: 4rem;
    }

    @media (max-width: 991px) {
      padding: 2.5rem;
    }

    @media (max-width: 575px) {
      padding: 2.5rem 1.5rem;
    }

    .common-heading {
      margin-bottom: 5.4rem;

      @media (max-width: 1679px) {
        margin-bottom: 3rem;
      }
    }

    .checkboxStyle {
      margin-bottom: 2.7rem;

      @media (max-width: 1679px) {
        margin-bottom: 1.8rem;
      }
    }
  }
}