@import '../../../../assets/_var.scss';

.Admin_leftside_layout {
    position: fixed;
    left: 0;
    background-color: $black;
    border-right: 1px solid rgba($white, 0.15);
    top: 0;
    max-width: 26rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    padding: 1.2rem 0;
    width: 100%;
    transition: 200ms ease-in-out;

    .sidebar_logo {
        width: 10rem;
        display: block;
        margin: 0 auto 3rem;
    }

    .header_links {
        ul {
            li {
                margin-bottom: 0.1rem;

                a {
                    width: 100%;
                    padding: 2rem 3rem;
                    display: flex;
                    align-items: center;
                    font-size: 1.8rem;
                    color: $white;
                    position: relative;

                    &::after {
                        content: "";
                        width: 0.3rem;
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    svg {
                        flex-shrink: 0;
                        margin-right: 1rem;
                        width: 2rem;
                        height: 2rem;

                        path {
                            fill: $white;
                        }
                    }

                    &.active,
                    &:hover {
                        color: $basecolor2;
                        background-color: rgba($baseColor, 0.2);

                        svg {
                            path {
                                fill: $basecolor2;
                            }
                        }

                        &::after {
                            background-color: $basecolor2;
                        }
                    }

                    @media (max-width: 991px) {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        transform: translateX(-100%);

        &.active {
            transform: translateX(0);
        }
    }

}

.sidebar_overlay {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($black, 0.0);
    backdrop-filter: blur(1rem);
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    top: 0;
    left: 0;
    display: none;

    @media (max-width: 767px) {
        display: block;
        transition: 200ms ease-in-out;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }
}