@import "../../../../../assets/_var.scss";

.image-modal {
  .modal-content {
    .modal-body {
      padding: 3rem;
    }
  }
  &_wrap {
    position: relative;
    .close-button {
      position: absolute;
      padding: 1rem;
      top: -5rem;
      right: -5rem;
      background-color: $secondaryColor;
      border-radius: 50%;

      @media (max-width: 767px) {
        right: -4rem;
        padding: 0.7rem;
      }

      svg path {
        stroke: $white;
      }
    }
  }

  &_img {
    display: flex;
    align-items: center; /* Center the image vertically */
    justify-content: center; /* Center the image horizontally */
    border-radius: 0.8rem;
    overflow: hidden;
    position: relative;
    // padding-bottom: 100%;
    // background-color: $blueGray3;
  }

  &_btns {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;

    @media (max-width: 767px) {
      gap: 1rem;
    }

    .prev-button {
      transform: rotate(180deg);
    }

    button {
      opacity: 0.6;
      // @extend %transition;
      &:hover {
        opacity: 1;
      }

      @media (max-width: 767px) {
        svg {
          width: 3.5rem;
          height: 3.5rem;
        }
      }
    }
  }
}
